import React from "react";
import ReactDOM from "react-dom";
import gsap from "gsap";

import TempImage from "../../images/testing-image.jpg";
import ClearSvg from "../../images/clear.svg";

import "../../styles/realisations/big.scss";
import eventBus from "../utils/eventBus.js";

import imagesLoaded from "imagesloaded";

export class Portal extends React.Component {
  constructor(props) {
    super(props);

    this.modalRoot = document.body;
    this.el = document.createElement("div");
  }

  componentDidMount() {
    console.log("THIS qshbdjqsbhdk", document.body);
    console.log(ReactDOM);
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

class BigPicture extends React.Component {
  constructor(props) {
    super(props);

    this.mousePos = { x: "50%", y: "80%" };

    this.body = React.createRef();
    this.cursor = React.createRef();
    this.bg = React.createRef();
    this.picture = React.createRef();
    this.state = {
      src: { TempImage },
    };
  }

  componentDidMount() {
    this.setState({
      src: document.querySelector("[data-viewer] picture").dataset.src, // Il faut utiliser le data-src pour le fullscreen
    });
    console.log(
      "SRC: ",
      document.querySelector("[data-viewer] picture").dataset.src
    );
    console.log("Component Mounted");
    this.body.current.addEventListener(
      "mousemove",
      this.setMousePosition,
      true
    );
    this.renderCross();
    //let tl = gsap.timeline()
    gsap.set(this.picture.current, {opacity: 0});
    imagesLoaded(this.picture.current, () => {
      gsap.set(this.picture.current, {opacity: 1});
      gsap.set("[data-viewer]", { opacity: 0 });
      gsap.fromTo(
        this.picture.current,
        {
          height: this.props.height,
          width: this.props.width,
          top: this.props.top,
          left: this.props.left,
          position: "absolute",
          translateY: "0%",
          translateX: "0%",
        },
        {
          height: "calc(100% - 180px)",
          width: "calc(100% - 180px)",
          top: "50%",
          left: "50%",
          translateY: "-50%",
          translateX: "-50%",
        }
      );
      console.log(this.bg.current);
      gsap.fromTo(
        [this.bg.current, this.cursor.current],
        { opacity: 0 },
        { opacity: 1 }
      );
    });
  }

  componentWillUnmount() {
    this.body.current.removeEventListener(
      "mousemove",
      this.setMousePosition,
      true
    );
  }

  setMousePosition = (e) => {
    this.mousePos = this.getMousePosition(e);
  };

  /**
   * Gets computed translate values
   * @param {HTMLElement} element
   * @returns {Object}
   */
  getTranslateValues(element) {
    const style = window.getComputedStyle(element);
    const matrix =
      style["transform"] || style.webkitTransform || style.mozTransform;

    // No transform property. Simply return 0 values.
    if (matrix === "none" || typeof matrix === "undefined") {
      return {
        x: 0,
        y: 0,
        z: 0,
      };
    }

    // Can either be 2d or 3d transform
    const matrixType = matrix.includes("3d") ? "3d" : "2d";
    const matrixValues = matrix.match(/matrix.*\((.+)\)/)[1].split(", ");

    // 2d matrices have 6 values
    // Last 2 values are X and Y.
    // 2d matrices does not have Z value.
    if (matrixType === "2d") {
      return {
        x: matrixValues[4],
        y: matrixValues[5],
        z: 0,
      };
    }

    // 3d matrices have 16 values
    // The 13th, 14th, and 15th values are X, Y, and Z
    if (matrixType === "3d") {
      return {
        x: matrixValues[12],
        y: matrixValues[13],
        z: matrixValues[14],
      };
    }
  }

  getMousePosition = (ev) => {
    let posx = 0;
    let posy = 0;
    if (!ev) ev = window.event;
    /** if (ev.pageX || ev.pageY) {
      posx = ev.pageX //- this.body.current.offsetLeft;
      posy = ev.pageY //- this.body.current.offsetTop;
    } else */
    if (ev.clientX || ev.clientY) {
      posx = ev.clientX; //+ this.body.current.scrollLeft;
      posy = ev.clientY; //+ this.body.current.scrollTop;
    }
    return { x: posx, y: posy };
  };

  renderCross = (e) => {
    if (this.cursor.current) {
      this.cursor.current.style.left =
        this.mousePos.x /** - this.getTranslateValues(translateContent) */ +
        "px";
      this.cursor.current.style.top =
        this.mousePos.y /** + this.getTranslateValues(translateContent).y */ +
        "px";
    }
    window.requestAnimationFrame(() => this.renderCross());
  };

  closeViewer = (e) => {
    console.log("close viewer");
    gsap.fromTo(
      this.picture.current,
      {
        height: "calc(100% - 180px)",
        width: "calc(100% - 180px)",
        top: "50%",
        left: "50%",
        translateY: "-50%",
        translateX: "-50%",
      },
      {
        height: this.props.height,
        width: this.props.width,
        top: this.props.top,
        left: this.props.left,
        translateY: "0%",
        translateX: "0%",
      }
    );
    gsap.to(this.bg.current, {
      opacity: 0,
      onComplete: () => {
        gsap.set("[data-viewer]", { opacity: 1 });
        eventBus.dispatch("closeBigPicture");
      },
    });
  };

  render() {
    return (
      <div
        className="Big__Wrapper big"
        ref={this.body}
        onClick={this.closeViewer}
      >
        <div className="big_background" ref={this.bg}></div>
        <picture className="big__picture" ref={this.picture}>
          <img src={this.state.src} alt="" className="big__image"></img>
        </picture>
        <button id="cursor" ref={this.cursor}>
          <img src={ClearSvg} alt="Icône fermée"></img>
        </button>
      </div>
    );
  }
}

export default BigPicture;
