import * as React from 'react'
import { Link } from 'gatsby'
import * as ButtonStyle from '../../styles/utils/button.module.scss'

const Button = ({value, type, to, onClick, form}) => {

    if(type === 'submit'){
        return(
            <button type="submit" className={ButtonStyle.wrapper + ' Button'} onClick={onClick} form={form}>
                <div className={ButtonStyle.circle_wrapper}>
                    <div className={ButtonStyle.circle}></div>
                </div>
                <p className={ButtonStyle.p}>{value}</p>
            </button>
        )
    }
    return (
        <Link to={to} className={ButtonStyle.wrapper + ' Button'}>
            <div className={ButtonStyle.circle_wrapper}>
                <div className={ButtonStyle.circle}></div>
            </div>
            <p className={ButtonStyle.p}>{value}</p>
        </Link>
    )
}

export default Button